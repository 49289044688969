"use strict";

//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");
require("morris.js/morris.js");
require("block-ui");
require("autosize");
require("clipboard");
require("jquery-editable-select");
require("jquery-editable-select/dist/jquery-editable-select.css");

window.moment = require("moment");
require("moment-timezone");
window.Sticky = require("sticky-js");
window.Chart = require("chart.js");
window.Raphael = require("raphael");
window.Cookies = require("js-cookie");
window.Popper = require("popper.js");
require("jquery-form");

// Toastr
require("toastr/build/toastr.css");
window.toastr = require("toastr");

// Tooltips
import Tooltip from "tooltip.js";

window.Tooltip = Tooltip;

// Perfect-Scrollbar
require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
//** End: Globally mandatory plugins


////** Begin: Global optional plugins
//// Owl.Carousel
//require("owl.carousel/dist/assets/owl.carousel.css");
//require("owl.carousel/dist/assets/owl.theme.default.css");
//require("owl.carousel");

//// Daterangepicker
//require("bootstrap-daterangepicker/daterangepicker.css");
//require("bootstrap-daterangepicker");

//// Bootstrap-Select
//require("bootstrap-select/dist/css/bootstrap-select.css");
//require("bootstrap-select");

//// Bootstrap-Session-Timeout
//require("metronic/_theme/plugins/bootstrap-session-timeout/dist/bootstrap-session-timeout.js");

//// jQuery-Idletimer
//require("metronic/_theme/plugins/jquery-idletimer/idle-timer.js");

//// Bootstrap-Switch
//require("bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css");
//require("bootstrap-switch");
//require("metronic/_theme/js/global/integration/plugins/bootstrap-switch.init.js");

//// Sweetalert2
//require("sweetalert2/dist/sweetalert2.css");
//import swal from "sweetalert2/dist/sweetalert2";
//window.swal = swal;
//require("es6-promise-polyfill/promise.min.js");
//require("metronic/_theme/js/global/integration/plugins/sweetalert2.init");

//// Bootstrap-Notify
//// require("bootstrap-notify");
//// require("metronic/_theme/js/global/integration/plugins/bootstrap-notify.init.js");

//// Bootstrap-Datepicker
require("bootstrap-datepicker/dist/css/bootstrap-datepicker3.css");
require("bootstrap-datepicker");
require("metronic/_theme/js/global/integration/plugins/bootstrap-datepicker.init");

//// Bootstrap-Datetimepicker
require("bootstrap-datetime-picker/css/bootstrap-datetimepicker.css");
require("bootstrap-datetime-picker");

//// Select2
//require("select2/dist/css/select2.css");
//require("select2");

//// Bootstrap-Timepicker
//require("bootstrap-timepicker/css/bootstrap-timepicker.css");
//require("bootstrap-timepicker");
//require("metronic/_theme/js/global/integration/plugins/bootstrap-timepicker.init");

//// Tagify
//require("@yaireo/tagify/dist/tagify.css");
//window.Tagify = require("@yaireo/tagify/dist/tagify");
//require("@yaireo/tagify/dist/tagify.polyfills.min");

//// Typeahead
//window.Bloodhound = require("corejs-typeahead");
//window.Handlebars = require("handlebars/dist/handlebars.js");

//// Dropzone
//require("dropzone/dist/dropzone.css");
//window.Dropzone = require("dropzone");
//require("metronic/_theme/js/global/integration/plugins/dropzone.init");

//// ClipboardJS
//window.ClipboardJS = require("clipboard");

//// Autosize
//window.autosize = require("autosize");

//// Summernote
//require("summernote/dist/summernote.css");
//require("summernote");

//// Quill
//require("quill/dist/quill.snow.css");
//window.Quill = require("quill");

//// Inputmask
//require("inputmask");

//// iOn-Rangeslider
//require("ion-rangeslider/css/ion.rangeSlider.css");
//require("ion-rangeslider");

//// jQuery.Repeater
//require("jquery.repeater");

//// noUISlider
//require("nouislider/distribute/nouislider.css");
//window.noUiSlider = require("nouislider");

//// Wnumb
//window.wNumb = require("wnumb");

//// jQuery-Validation
//require("jquery-validation");
//require("jquery-validation/dist/additional-methods.js");
//require("metronic/_theme/js/global/integration/plugins/jquery-validation.init");

//// Bootstrap-Multiselectsplitter
//require("metronic/_theme/plugins/bootstrap-multiselectsplitter/bootstrap-multiselectsplitter.min.js");

//// Bootstrap-Maxlength
//require("bootstrap-maxlength");

//// Bootstrap-Touchspin
//require("bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css");
//require("bootstrap-touchspin");

//// Bootstrap-Markdown
//require("bootstrap-markdown/css/bootstrap-markdown.min.css");
//require("bootstrap-markdown/js/bootstrap-markdown");
//require("metronic/_theme/js/global/integration/plugins/bootstrap-markdown.init");

//// Animate.css
require("animate.css/animate.css");

//// Dual-listbox
//import DualListbox from "dual-listbox";
//window.DualListbox = DualListbox;
//require("dual-listbox/dist/dual-listbox.css");

//// Cropper.js
//window.Cropper = require("cropperjs");
//require("cropperjs/dist/cropper.css");

//// Font Icons
require("metronic/_theme/plugins/line-awesome/css/line-awesome.css");
require("metronic/_theme/plugins/flaticon/flaticon.css");
require("metronic/_theme/plugins/flaticon2/flaticon.css");
require("@fortawesome/fontawesome-free/css/all.min.css");
//require("socicon");
////** End: Global optional plugins

//// // CKEditor Balloon Block
//// window.BalloonEditor = require("@ckeditor/ckeditor5-build-balloon-block");
//// // CKEditor Balloon
//// window.BalloonEditor = require("@ckeditor/ckeditor5-build-balloon");
//// // CKEditor Classic
//// window.ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
//// // CKEditor Document
//// window.DecoupledEditor = require("@ckeditor/ckeditor5-build-decoupled-document");
//// // CKEditor Inline
//// window.InlineEditor = require("@ckeditor/ckeditor5-build-inline");

//// Datatables.net
require("datatables.net");
require("datatables.net-bs4");
//require("datatables.net-autofill");
//require("datatables.net-autofill-bs4");
//require("datatables.net-buttons");
//require("datatables.net-buttons-bs4");
//require("datatables.net-buttons/js/buttons.print.js");
//require("datatables.net-buttons/js/buttons.html5.js");
//require("datatables.net-buttons/js/buttons.flash.js");
//require("datatables.net-buttons/js/buttons.colVis.js");
//require("datatables.net-colreorder-bs4");
//require("datatables.net-fixedcolumns-bs4");
//require("datatables.net-fixedheader-bs4");
//require("datatables.net-keytable-bs4");
require("datatables.net-responsive-bs4");
//require("datatables.net-rowgroup-bs4");
//require("datatables.net-rowreorder-bs4");
//require("datatables.net-scroller-bs4");
//require("datatables.net-select-bs4");
//require("metronic/_theme/js/global/integration/plugins/datatables.init.js");

//window.JSZip = require("jszip/dist/jszip.js");
//var pdfMake = require("pdfmake/build/pdfmake.js");
//var pdfFonts = require("pdfmake/build/vfs_fonts.js");
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

require("datatables.net-bs4/css/dataTables.bootstrap4.css");
//require("datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css");
//require("datatables.net-autofill-bs4/css/autoFill.bootstrap4.min.css");
//require("datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css");
//require("datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css");
//require("datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css");
//require("datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css");
require("datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css");
//require("datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css");
//require("datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css");
//require("datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css");
//require("datatables.net-select-bs4/css/select.bootstrap4.min.css");

//// Flot
//// require("flot");

//// require("flot/source/jquery.flot.resize.js");
//// require("flot/source/jquery.flot.categories.js");
//// require("flot/source/jquery.flot.pie.js");
//// require("flot/source/jquery.flot.stack.js");
//// require("flot/source/jquery.flot.crosshair.js");
//// require("flot/source/jquery.flot.axislabels");

//// Fullcalendar
require("@fullcalendar/core/main.css");
// require("@fullcalendar/daygrid/main.css");
//require("@fullcalendar/list/main.css");
require("@fullcalendar/timegrid/main.css");

// window.FullCalendar = require("@fullcalendar/core");
// window.FullCalendarDayGrid = require("@fullcalendar/daygrid");
//window.FullCalendarGoogleCalendar = require("@fullcalendar/google-calendar");
//window.FullCalendarInteraction = require("@fullcalendar/interaction");
//window.FullCalendarList = require("@fullcalendar/list");
// window.FullCalendarTimeGrid = require("@fullcalendar/timegrid");

//// GMaps
//// window.GMaps = require("gmaps");

//// Dependencies
//window.jQuery = window.$ = require("jquery");
//require("bootstrap/js/dist/tooltip");

//// jQueryUI
//require("jquery-ui");
//require("jquery-ui/ui/widgets/sortable");
//require("jquery-ui/ui/disable-selection");

//require("jquery-ui/themes/base/all.css");

//// jQVMap
//require("jqvmap/dist/jqvmap.css");
//require("jqvmap/dist/jquery.vmap.js");
//require("jqvmap/dist/maps/jquery.vmap.world.js");
//require("jqvmap/dist/maps/jquery.vmap.russia.js");
//require("jqvmap/dist/maps/jquery.vmap.usa.js");
//require("jqvmap/dist/maps/jquery.vmap.germany.js");
//require("jqvmap/dist/maps/jquery.vmap.europe.js");

//// jsTree
//require("jstree");
//require("jstree/dist/themes/default/style.css");

//// jKanban
//require("jkanban/dist/jkanban.css");
//window.kanban = require("jkanban");

//// Tinymce
//window.tinymce = require("tinymce");
//require("tinymce/themes/silver/theme");
//require("tinymce/themes/mobile/theme");

//// Uppy
//require("uppy/dist/uppy.css");
//window.Uppy = require("uppy");

//// Bootstrap-ColorPicker
require("bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css");
window.colorpicker = require("bootstrap-colorpicker");
